import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { jwtDecode } from 'jwt-decode';

const PrivateAdminRoute = ({ children }) => {
    const emails_admin = ["badii.abdelkhalak@flydev.fr"];
    const { isLoggedIn } = useAuth();
    const location = useLocation();
    const token = localStorage.getItem('token');
    if (!isLoggedIn) {
        
        if (!token) {
        console.log("Aucun token trouvé dans le localStorage. Redirection vers la page de connexion.");
        return <Navigate to="/connexionseaux" state={{ from: location }} />;
        }
        
    }else if(token){
        console.log("token:",token);
        const decoded = jwtDecode(token);
        if(!decoded.isadmin){
            console.log("Aucun token trouvé dans le localStorage. Redirection vers la page de connexion.");
            return <Navigate to="/404" state={{ from: location }} />;
        }
        console.log(decoded);
    }

    return children ? children : <Outlet />;
};

export default PrivateAdminRoute;