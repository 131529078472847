import React from "react";

const sizes = {
  body_1_regular: "font-gilroy text-base font-normal not-italic",
  body_2_regular: "font-gilroy text-sm font-normal not-italic",
  button_1_medium2: "text-base font-normal not-italic",
  caption: "font-gtamerica text-xs font-normal not-italic",
  texts: "text-base font-normal not-italic",
};

const Text = ({ children, className = "", as, size = "button_1_medium2", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-default-white font-leaguespartan ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
