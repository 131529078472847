import { Helmet } from "react-helmet";
import { Img } from "../../components";
import Sidebar from "../../components/Sidebar";
import React, { useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { getallUsers } from "services/adminServices";
import Footer from "components/Footer";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "services/confServices";

export default function AdminEmbargoPage() {
    const [error, setError] = useState(null); // Gère les erreurs potentielles
    const [allUsers, setAllUsers] = useState([]); // Gère les erreurs potentielles

    const fetchData = async () => {
        try {
            const allUsers = await getallUsers();
            setAllUsers(allUsers);
        } catch (err) {
            setError(err.message);
        }
    };
    
    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, []);

    if (error) {
        return <div className="error">{error}</div>; // Affiche un message d'erreur en cas d'échec de l'appel API
    }

    return (
        <>
            <Helmet>
                <title>ReCut</title>
                <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
            </Helmet>
            <div className="relative w-full">
                <div className="flex flex-col z-[5] gap-[27px]">
                    <AdminGestionPageDistributeurNavbar />
                    <div className="ml-[54px] flex w-[100%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
                        <Sidebar />
                        <div className="">
                            <div className="flex w-[100%] flex-col items-end gap-[55px] md:w-full md:p-5 sm:gap-[27px]">
                                <div className="flex flex-col gap-[37px] self-stretch">
                                    <div className="container-xs">
                                        <div className="self-stretch mb-5">
                                            <DataTable 
                                                value={allUsers} 
                                                paginator 
                                                filterDisplay="row"
                                                rows={10} 
                                                sortField="sequenceCount" 
                                                sortOrder={-1}
                                                className="p-datatable-striped"
                                                globalFilterFields={['filmName', 'sequenceName', 'durationClip', 'email']}
                                                style={{ backgroundColor: 'transparent', color: '#fff' }}
                                            >
                                                <Column 
                                                    className="w-[100px]" 
                                                    field="filmName" 
                                                    header="Profil" 
                                                    body={(rowData) => (
                                                        <Img src={`${rowData.imageprofil && rowData.imageprofil !== "" ? rowData.imageprofil : "/images/icons/profile.png"}`}
                                                             alt={`${rowData.email}`}
                                                             className="h-[50px] rounded-[4px] object-contain"
                                                        />
                                                    )}  
                                                />
                                                <Column field="createdAt" header="Created At" body={(rowData) => `${formatDate(rowData.createdAt)}`} sortable />
                                                
                                                {/* Email column with filter enabled */}
                                                <Column 
                                                    field="email" 
                                                    header="Email" 
                                                    body={(rowData) => `${rowData.email}`} 
                                                    sortable 
                                                    filter 
                                                    filterPlaceholder="Rechercher par email" 
                                                    showFilterMenu={false} // Default filter
                                                />

                                                <Column field="isadmin" header="Admin" body={(rowData) => `${rowData.isadmin ? "OUI" : "NON"}`} sortable />
                                                
                                                <Column 
                                                    header="Gestion" 
                                                    className="overflow-hidden" 
                                                    body={(rowData) => (
                                                        <Link to={`/admin/embargo/user/${rowData.id}`}
                                                            style={{ backgroundColor: 'transparent', border: 'none', color: '#fff', cursor: 'pointer' }}
                                                        >
                                                            <Img 
                                                                src="/images/img_settings_default_white.svg" 
                                                                alt="Gestion"
                                                                className="h-[40px] sm:h-[40px] object-cover" 
                                                            />
                                                        </Link>
                                                    )}
                                                />
                                            </DataTable>
                                        </div>
                                        <div className="mb-3 text-center">
                                            <button 
                                                className="btn-clip"
                                                style={{ padding: "10px 20px", color: "white", borderRadius: "5px", cursor: "pointer" }}
                                            >
                                                Télécharger CSV
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
