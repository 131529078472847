import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input, Heading } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import Footer from "components/Footer";


export default function MentionslPage() {
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">

        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[66%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            
            <div className="flex flex-1 flex-col items-start justify-start rounded-lg bg-gray-900 p-[50px] md:p-5">
              <Heading size="headline_2" as="h2" className="text-3xl font-bold mb-4 text-center w-[100%]">
                Mentions légales
              </Heading>
              <Text as="p" className="text-xl">Éditeur du site :</Text>
              <Text as="p" className="max-w-max">- Nom de l'entreprise : ReCut</Text>
              <Text as="p" className="max-w-max">- Forme juridique : SAS</Text>
              <Text as="p" className="max-w-max">- Adresse : 40 Rue Alexandre Dumas, 75011 Paris</Text>
              <Text as="p" className="max-w-max">- Téléphone : 06.67.01.74.30</Text>
              <Text as="p" className="max-w-max">- Email : noel@recut.fr</Text>
              <Text as="p" className="text-xl">Directeur de la publication :</Text>
              <Text as="p" className="max-w-max">- Nom : Esnault Noël</Text>
              <Text as="p" className="max-w-max">- Email : dpo@recut.fr</Text>
              <Text as="p" className="text-xl">Hébergeur :</Text>
              <Text as="p" className="max-w-max">- Nom de l'hébergeur : OVH Adresse : 2 rue Kellermann, 59100 Roubaix, France Téléphone :</Text>
              <Text as="p" className="max-w-max">+33 9 72 10 10 07 Site web : www.ovh.com</Text>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
