import { Link, Navigate } from "react-router-dom";
import { Text, Img } from "./..";
import React, { useState } from "react";
import { isAdmin } from "services/authServices";

export default function MenuMenuclient() {
  const [toNavigate, setToNavigate] = useState(false); 

  const handleLogout = () => {
    localStorage.clear();
    setToNavigate(true);
  };
  if (toNavigate) return <Navigate to="/connexionseaux" />;
  return (
    <div
      className={`flex flex-col  md:w-full  gap-[15px] md:ml-0`}
    >
      {isAdmin() &&
      <>
        <Link to="/admin/statistiques" className="flex items-center gap-5 max-w-max">
          <Img src="/images/img_statistics.svg" alt="film" className="h-[26px]" />
          <Text as="p" className="max-w-max">Mes Statistiques</Text>
        </Link>
        <Link to="/admin/embargo" className="flex items-center gap-5 max-w-max">
          <Img src="/images/img_settings_default_white.svg" alt="film" className="h-[26px]" />
          <Text as="p" className="max-w-max">Gestion d'un embargo</Text>
        </Link>
      </>
      
      }
      <Link to="/messsequencessix" className="flex items-center gap-5 max-w-max">
        <Img src="/images/img_film.svg" alt="film" className="h-[26px]" />
        <Text as="p" className="max-w-max">Mes séquences</Text>
      </Link>
      {/* <Link to="/mescommandesone" className="flex items-center gap-5 max-w-max">
        <Img src="/images/img_cart_default_white_26x23.svg" alt="cart" className="h-[26px]" />
        <Text as="p" className="max-w-max">Mes commandes (0)</Text>
      </Link>
      <Link to="/wallet" className="flex items-start gap-5 max-w-max">
        <Img src="/images/img_film.svg" alt="film" className="h-[26px]" />
        <Text as="p" className="max-w-max">Wallet</Text>
      </Link>
      
      <Link to="/prfrencesde" className="flex items-start gap-5 max-w-max">
        <Img src="/images/img_settings_default_white.svg" alt="settings" className="h-[23px] w-[23px]" />
        <Text as="p" className="max-w-max">Préférences de contenu</Text>
      </Link> */}
      {/* <Link to="/mesinfospersonnelles" className="flex items-center gap-5 self-stretch max-w-max">
        <Img src="/images/img_lock.svg" alt="lock" className="h-[23px] w-[23px]" />
        <Text as="p" className="max-w-max">Profil</Text>
      </Link> */}
      <Link to="/mentions-legales" className="flex items-center gap-5 max-w-max">
        <Img src="/images/img_documenttext.svg" alt="image" className="h-[23px] w-[23px]" />
        <Text as="p" className="max-w-max">Mentions légales</Text>
      </Link>
      <a className="flex items-center gap-5 max-w-max cursor-pointer" onClick={handleLogout}>
        <Img src="/images/img_logout.svg" alt="image" className="h-[23px] w-[23px]" />
        <Text as="p" className="max-w-max">Déconnexion</Text>
      </a>
    </div>
  );
}
