import axios from 'axios';
import apiClient from '../axios-interceptor';

const apiUrl = process.env.REACT_APP_API_URL+"/admin";

// Function to get UserSequence by connected user
export const getStatistique = async () => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique'); // Adjust endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
}


export const getStatistiqueFilms = async () => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique/films'); // Adjust endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
}

export async function getStatistiqueFilm(id) {
    try {
      const response = await apiClient.get(apiUrl+'/statistique/film/'+id);
      if(response.data){
        return response.data; 
      }
      
    } catch (error) {
      console.log('Error fetchData:', error);
      throw error;
    }
    return null;
}

export const getUserSequences = async (id) => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique/user/'+id); // Adjust endpoint as necessary
    return response.data.userSequences;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
};

export const getUserSequencesLogs = async (id) => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique/user/'+id+'/logs'); // Adjust endpoint as necessary
    return response.data.userSequences;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
};

export const getAllUsersSequencesLogs = async () => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique/users/logs'); // Adjust endpoint as necessary
    return response.data.userSequences;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
};

export const getallFilmsAndSequences = async () => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique/allFilmsAndSequences'); // Adjust endpoint as necessary
    return response.data.films;
  } catch (error) {
    console.error('Error fetching Films:', error);
    throw error;
  }
};

export const getAllUsersSequences = async () => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique/users'); // Adjust endpoint as necessary
    return response.data.dataUserCount;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
};

export const getStatistiqueSequences = async (id) => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique/sequence/'+id); // Adjust endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
};


export const getStatistiqueSequencesUsers = async (id) => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique/sequence/'+id+'/users'); // Adjust endpoint as necessary
    return response.data.dataUserCount;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
};

export const getStatistiqueSequencesFormat = async (id) => {
  try {
    const response = await apiClient.get(apiUrl+'/statistique/sequence/'+id+'/formatSequence'); // Adjust endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
};

export const getallUsers = async () => {
  try {
    const response = await apiClient.get(apiUrl+'/allusers'); // Adjust endpoint as necessary
    return response.data.users;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getUserById = async (id) => {
  try {
    const response = await apiClient.get(apiUrl+'/user/'+id); // Adjust endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
};

export const updateUserAccess = async (id, updateData) => {
  try {
    const response = await apiClient.put(apiUrl+'/useraccess/'+id, updateData);
    return response.data; // Return the updated user data
  } catch (error) {
    console.error('Error updating user access:', error);
    throw error; // Throw error so the calling function can handle it
  }
};