import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input, Heading, Button, ReactTable } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React, { useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import AdminCatalogueRowonehundredfo from "components/AdminCatalogueRowonehundredfo";
import AdminCatalogueCardsquence1 from "components/AdminCatalogueCardsquence1";
import AdminCatalogueRowlaudition from "components/AdminCatalogueRowlaudition";
import { createColumnHelper } from "@tanstack/react-table";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getFilms } from "services/filmsServices";
import { getAllUsersSequences, getStatistique, getStatistiqueFilms } from "services/adminServices";
import { DataTable } from 'primereact/datatable'; // Import PrimeReact DataTable
import { Column } from 'primereact/column'; // Import PrimeReact Column
import "primereact/resources/themes/saga-blue/theme.css"; // Thème PrimeReact
import "primereact/resources/primereact.min.css"; // Styles de base PrimeReact
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; // Import du CSS pour le carousel
import Footer from "components/Footer";
import { getCurrentDateTime,formatDate} from "services/confServices";

export default function AdminStatistiquePage() {
  const [films, setFilms] = useState([]);
  const [statistiquesData, setStatistiquesData] = useState([]);
  const [filmsStats, setFilmsStats] = useState([]);
  const [loading, setLoading] = useState(true); 
  const appUrl = process.env.REACT_APP_URL;
  const [dataUsers, setDataUsers] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function
  const [filterText, setFilterText] = useState(""); 




  useEffect(() => {
    fetchFilms();
    fetchStatistique();
    const interval = setInterval(() => {
      fetchFilms();
      fetchStatistique();
    }, 10000);
    window.scrollTo(0, 0);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchStatistique = async () => {
    try {
      const statistiqueData = await getStatistique();
      const statistiqueDataFilms = await getStatistiqueFilms();
      
      setStatistiquesData([
        { image: "/images/img_film.svg", count: statistiqueData.sequence, label: "Séquences" },
        { image: "/images/img_light_bulb.svg", count: statistiqueData.download, label: "Téléchargements" },
        { image: "/images/icon-users.svg", count: statistiqueData.users, label: "Utilisateurs",href:"/admin/statistique/users/logs" },
      ]);
      setFilmsStats(statistiqueDataFilms); // Stocker les statistiques des films
      const statistiqueUserData = await getAllUsersSequences();
      setDataUsers(statistiqueUserData);
      console.log(statistiqueUserData.dataUserCount);
    } catch (error) {
      console.error("Error fetching statistics:", error);
    }
  };

  const fetchFilms = async () => {
    try {
      const filmsData = await getFilms();
      setFilms(filmsData.films);
    } catch (error) {
      console.error("Error fetching films:", error);
    }
  };

  // Configuration du carousel
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1650 }, items: 5 },
    desktop: { breakpoint: { max: 1650, min: 1250 }, items: 4 },
    tablet: { breakpoint: { max: 1250, min: 464 }, items: 3 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };


  

  const handleClickLogs = (id) => {
    // Exemple d'action : rediriger vers une page spécifique
    <Navigate to={`/admin/statistique/user/${id}/logs`} />
  };


  

  const filteredData = dataUsers?.filter(item => 
    item.email.toLowerCase().includes(filterText.toLowerCase()) || 
    item.film.toLowerCase().includes(filterText.toLowerCase())
  );

  // Fonction pour exporter les données sous forme de CSV
  const exportCSVFilm = () => {
    const csvRows = [];
    const headers = ['Chalenge', 'Nombre de sequences', 'Nombre clips', 'Nombre utilisateur'];
    csvRows.push(headers.join(';')); // Ajouter les en-têtes

    // Ajouter les lignes des données
    filmsStats.forEach((film) => {
      const row = [
        film.filmName,
        film.sequenceCount,
        film.totalClipSequenceCount,
        film.totalUserSequenceCount
      ];
      csvRows.push(row.join(';'));
    });

    // Créer un Blob à partir des données CSV
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    const current_datetime = getCurrentDateTime();
    a.setAttribute('download', 'films_statistiques-'+current_datetime+'.csv');
    a.click();
  };


  // Fonction pour exporter les données sous forme de CSV
  const exportCSVUser = () => {
    const csvRows = [];
    const headers = ['Chalenge','Email', 'Cree le', 'Nombre de clips', 'Duree des clips (s)'];
    csvRows.push(headers.join(';')); // Ajouter les en-têtes

    // Ajouter les lignes des données
    filteredData.forEach((user) => {
      const row = [
        user.film,
        user.email,
        formatDate(user.createdAt),
        user.sequenceCount,
        user.totalDuration
      ];
      csvRows.push(row.join(';'));
    });

    // Créer un Blob à partir des données CSV
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    const current_datetime = getCurrentDateTime();
    a.setAttribute('download', 'all_users_statistiques-'+current_datetime+'.csv');
    a.click();
  };

  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[80%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            <div className="mt-12 flex w-[100%] flex-col gap-[60px] sm:gap-[30px]">
              <div className="flex w-[100%] flex-col items-end gap-[55px] md:w-full md:p-5 sm:gap-[27px]">
                <div className="flex flex-col gap-[37px] self-stretch">
                  <div className="flex w-[97%] gap-5 md:w-full md:flex-col">
                    {statistiquesData.map((d, index) => (
                      <AdminCatalogueRowonehundredfo
                        {...d}
                        key={"listonehundredf" + index}
                        className="w-[30%] md:w-full sm:p-5"
                      />
                    ))}
                  </div>
                  <div className="container-xs">
                    <Heading size="headline_3" as="h3">
                      LES CHALLENGES
                    </Heading>
                    <div className="flex mt-3 w-[78%] mb-5 gap-5 sm:w-[100%] sm:flex-row sm:overflow-scroll">
                      {Array.isArray(films) && films.length > 0 ? (
                        <div style={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}> {/* Conteneur avec des dimensions */}
                          <Carousel responsive={responsive}>
                            {films.map((film, index) => (
                              <Link to={"/admin/statistique/film/" + film.id} className="w-[150px]" key={index}>
                                <div className="mb-3">
                                  <Img 
                                    src={appUrl + "public/films/" + film.id + "-affiche.jpg"} 
                                    alt={film.title} 
                                    className="h-[200px] w-[150px] object-cover rounded-[10px]" 
                                  />
                                </div>
                                <div className="flex flex-1 flex-col items-start gap-2 sm:gap-1">
                                  <Heading size="subtitle_3" as="p" className="sm:text-sm limit-texte">
                                    {film.title}
                                  </Heading>
                                  <Link to={"/" + film.pseudo}>
                                    <Text as="p" className="!text-gray-500 sm:text-xs limit-texte">
                                      {film.sequenceCount} séquences
                                    </Text>
                                  </Link>
                                </div>
                              </Link>
                            ))}
                          </Carousel>
                        </div>
                      ) : (
                        <Text as="p" className="text-white sm:text-xs">No films available</Text>
                      )}
                    </div>
                    {/* Nouveau tableau pour les statistiques des films */}
                    <Heading size="headline_3" as="h3" className="mt-[50px] mb-[20px] text-center">
                      Statistiques Challenges
                    </Heading>
                    <div className="self-stretch mb-5">
                      <DataTable 
                        value={filmsStats} 
                        paginator 
                        rows={10} 
                        loading={!loading}
                        sortField="totalClipSequenceCount" // Champ à trier par défaut
                        sortOrder={-1} // Ordre décroissant
                        className="p-datatable-striped"
                        style={{ backgroundColor: 'transparent', color: '#fff' }}
                      >
                        <Column field="filmName" header="Chalenge"  className="overflow-hidden" 
                        body={(rowData) => (
                          <Link to={`/admin/statistique/film/${rowData.filmId}`}
                            style={{ backgroundColor: 'transparent', border: 'none', color: '#fff', cursor: 'pointer' }}
                          >
                            {rowData.filmName}
                          </Link>
                          
                        )}  
                        sortable />
                        <Column field="sequenceCount" className="overflow-hidden"  header="Nombre de séquences" body={(rowData) => `${rowData.sequenceCount}`} sortable />
                        <Column field="totalClipSequenceCount" className="overflow-hidden"  header="Nombre clips" body={(rowData) => `${rowData.totalClipSequenceCount}`} sortable />
                        <Column field="totalUserSequenceCount" className="overflow-hidden"  header="Nombre utilisateur" body={(rowData) => `${rowData.totalUserSequenceCount}`} sortable />
                      </DataTable>
                    </div>
                    {/* Bouton pour exporter le CSV */}
                    <div className="mb-3 text-center">
                      <button 
                        onClick={exportCSVFilm}
                        className="btn-clip" 
                        style={{ padding: "10px 20px", color: "white", borderRadius: "5px", cursor: "pointer" }}
                      >
                        Télécharger CSV
                      </button>
                    </div>


                    {/* Tableau des utilisateurs déjà existant */}
                    <Heading size="headline_3" as="h3" className="mt-[150px] mb-[20px] text-center">
                      Statistiques Utilisateurs
                    </Heading>
                    <div className="self-stretch mb-5">
                      {/* Add filter input */}
                      <div className="mb-3 flex">
                        <input
                          style={{border:"solid 1px #fff",padding:"20px",color:"#fff",borderRadius:"10px"}}
                          type="text"
                          placeholder="Filtrer par email ou date"
                          value={filterText}
                          onChange={e => setFilterText(e.target.value)}
                          className="p-2 border rounded-md w-[calc(100% - 50px)]"
                        />
                        
                      </div>
                      {/* Utilisation de PrimeReact DataTable */}
                      <DataTable 
                        value={filteredData} 
                        paginator 
                        loading={!loading}
                        rows={10} 
                        sortField="sequenceCount" // Champ à trier par défaut
                        sortOrder={-1} // Ordre décroissant
                        className="p-datatable-striped"
                        style={{ backgroundColor: 'transparent', color: '#fff' }}
                      >
                        <Column 
                          field="film" 
                          header="Chalenge" 
                          className="overflow-hidden" 
                          body={(rowData) => (
                            <Link to={`/admin/statistique/film/${rowData.filmId}`}
                              style={{ backgroundColor: 'transparent', border: 'none', color: '#fff', cursor: 'pointer' }}
                            >
                              {rowData.film}
                            </Link>
                            
                          )}  
                          sortable />
                        <Column 
                          field="email" 
                          className="overflow-hidden" 
                          header="Email" 
                          body={(rowData) => (
                            <Link to={`/admin/statistique/user/${rowData.userId}`}
                              style={{ backgroundColor: 'transparent', border: 'none', color: '#fff', cursor: 'pointer' }}
                            >
                              {rowData.email}
                            </Link>
                          )}
                          sortable
                        />
                        <Column field="createdAt" className="overflow-hidden"  header="Créé le" body={(rowData) => `${formatDate(rowData.createdAt)}`} sortable />
                        <Column field="sequenceCount" className="overflow-hidden"  header="Nombre de clips" body={(rowData) => `${rowData.sequenceCount}`} sortable />
                        <Column field="totalDuration" className="overflow-hidden"  header="Durée des clips (s)" body={(rowData) => `${rowData.totalDuration}`} sortable />
                        <Column 
                          header="Logs" 
                          className="overflow-hidden" 
                          body={(rowData) => (
                            <Link to={`/admin/statistique/user/${rowData.userId}/logs`}
                              style={{ backgroundColor: 'transparent', border: 'none', color: '#fff', cursor: 'pointer' }}
                            >
                              <Img 
                              src="/images/icons/icon-logs.svg" 
                              alt="Logs"
                              className="h-[40px] sm:h-[40px] object-cover" 
                              />
                            </Link>
                          )}
                        />
                      </DataTable>
                    </div>
                     {/* Bouton pour exporter le CSV */}
                     <div className="mb-3 text-center">
                      <button 
                        onClick={exportCSVUser} 
                        className="btn-clip"
                        style={{ padding: "10px 20px", color: "white", borderRadius: "5px", cursor: "pointer" }}
                      >
                        Télécharger CSV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
