import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React, { Suspense, useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { getUserSequences } from "services/userService";
import MesSequenceUser from "components/MesSequenceUser";
import Footer from "components/Footer";

export default function MesssequencessixPage() {
  const [sequences, setSequences] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nbSequences, setNbSequences] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserSequences();
        setSequences(data);
        console.log(data);
        setNbSequences(sequences.length)
      } catch (err) {
        setError(err.message);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[90%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            <div className="">
            
                <Suspense fallback={<div className="loader">Loading feed...</div>}>
                  {sequences.map((sequence, index) => (
                    <MesSequenceUser {...sequence} sequencedata={sequence} key={"tlchargement" + index} />
                  ))}
                </Suspense>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
