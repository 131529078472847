import { Helmet } from "react-helmet";
import { Text, Heading, Button, Img } from "../../components";
import AdminGestionPageDistributeurNavbar from "../../components/AdminGestionPageDistributeurNavbar";
import EditerMonExtraitCardsquence from "../../components/EditerMonExtraitCardsquence";
import FilmCardsquence from "../../components/FilmCardsquence";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { getSequence } from "services/sequencesServices";
import { getSequencesByFilm } from "services/filmsServices";
import { Player, ControlBar, ReplayControl, BigPlayButton, PlayToggle, ForwardControl, VolumeMenuButton, CurrentTimeDisplay, TimeDivider, PlaybackRateMenuButton, DurationDisplay, ProgressControl, FullscreenToggle } from 'video-react';
import 'video-react/dist/video-react.css';
import { Link } from "react-router-dom";
import FilmClipsquence from "components/FilmClipsquence";
import HLSSource from './HLSSource';
import Footer from "components/Footer";

export default function EditermonextraitPage() {
  const { id } = useParams();
  const [video, setVideo] = useState(null);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [showPopupClip, setShowPopupClip] = useState(null);
  const [videosByFilm, setVideosByFilm] = useState([]);
  const playerRef = useRef(null);
  const location = useLocation();
  const [dataTimeClipVideo, setDataTimeClipVideo] = useState({timeStart:-1,timeEnd:-1});
  const [disabledClipVideo, setDisabledClipVideo] = useState(false);
  const appUrl = process.env.REACT_APP_URL;
  const [producteur, setProducteur] = useState("pathe");

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.subscribeToStateChange((state) => {
        // Custom logic can go here
      });
      // setTimeout(() => {
      //   if (playerRef.current) {
      //     playerRef.current.play();
      //   }
      // }, 100);
    }

    fetchVideo(id);
    // Fais défiler vers le haut
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const handleUrlChange = () => {
      setVideo(null);
      fetchVideo(id, false);
      setShowPopupClip(false);
      console.log('URL a changé:', location.pathname);
      // Ajoutez ici la logique que vous souhaitez exécuter
    };
 
    handleUrlChange(); 
  }, [location, id]);

  const handleButtonClipClick = () => {
    setShowPopupClip(!showPopupClip);
    setDataTimeClipVideo({timeStart:-1,timeEnd:-1});
  };

  const fetchVideosByFilm = async (id) => {
    setDataTimeClipVideo({timeStart:-1,timeEnd:-1});
    try {
      const videoData = await getSequencesByFilm(id);
      console.log(videoData);
      console.log(videoData.sequences);
      setVideosByFilm(videoData.sequences);
    } catch (error) {
      console.error("Error fetching videos1:", error);
      return <Navigate to="/404" />;
    }
  };

  const fetchVideo = async (id, getsequence = true) => {
    
    try {
      const videoData = await getSequence(id);
      console.log(videoData);
      console.log(videoData.sequence);
      setVideo(videoData.sequence);
      setProducteur(videoData.sequence.film.producteur);
      if (getsequence) fetchVideosByFilm(videoData.sequence.filmId);
      setLoadingVideo(false);
      changeURL(
        videoData.sequence.producteur!=""?videoData.sequence.producteur:videoData.sequence.film.producteur,
        videoData.sequence.film.pseudo,
        convertToSlug(videoData.sequence.title),
        videoData.sequence.id
      );
    } catch (error) {
      setLoadingVideo(true);
      console.error("Error fetching videos2:", error);
      
    }
  };

  const convertToSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[\s\W-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

  const changeURL = (producteur,pseudo,title,id) => {
    window.history.pushState({}, "", "/"+producteur+"/"+pseudo+"/"+title+"/"+id);
  };
  
  if(loadingVideo && !video) return <Navigate to="/404" />;
  
  return (
    <>
      <Helmet>
        <title>ReCut - {video && video.title ? video.title : ""}</title>
        <meta name="description" content={video && video.desc ? video.desc : ""} />
        <meta property="og:title" content={`ReCut - ${video && video.title ? video.title : "ReCut"}`} />
        <meta property="og:description" content={video && video.desc ? video.desc : ""} />
        <meta property="og:image" content={video && video.id ? `https://recut.fr/public/sequences/${video.id}/${video.id}.jpg` : "https://recut.fr/images/logo-recut.png"} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`ReCut - ${video && video.title ? video.title : "ReCut"}`} />
      </Helmet>
     
      <div className="w-full">
        <div className="">
          <div
          className="absolute md:relative left-0 right-0 top-0 z-[2] bg-cover bg-no-repeat md:h-auto" 
          >
            <div className="flex flex-col items-center md:ml-[10px]">
              <AdminGestionPageDistributeurNavbar producteur={producteur} />
              <div className="container-xs mt-[7px] md:p-1 ">
                <div className="flex flex-col items-start gap-4 md:hidden">
                  <Heading size="headline_1" as="h1">
                    {video?.title}
                  </Heading>
                  <Link to={"/"+video?.film.producteur+"/"+video?.film.pseudo}>
                    <Text as="p">{video?.subtitle}</Text>
                  </Link>
                </div>
                <div className="flex flex-col items-start gap-4 hidden md:block">
                  <Heading size="headline_6" as="h6">
                  {video?.title}
                  </Heading>
                  <Link to={"/"+video?.producteur+"/"+video?.pseudo}>
                    <Text as="p">{video?.subtitle}</Text>
                  </Link>
                  
                </div>
              </div>
              
            </div>
          </div>
          <div>
            {video?.id>0 && 
            <HLSSource 
              dataTimeClipVideo={dataTimeClipVideo}
              disabledClipVideo={disabledClipVideo}
              src={appUrl+"public/hls/sequence_"+video?.id+"/720p.m3u8"}
            />
            }
          </div>
          
          <div className="self-end block-popup-clip-recut">
            <button className="btn-clip" onClick={handleButtonClipClick}>
              <Img
                src="/images/icon-clip.svg"
                alt="image"
                className="h-[30px] sm:h-[30px] object-cover"
              />
              CLIP
            </button>
            {/* <button className="btn-clip sm:mt-[40px]" onClick={handleButtonClipClick}>
              <Img
                src="/images/download.svg"
                alt="image"
                className="h-[30px] sm:h-[30px] object-cover"
              />
              TELECHARGER
            </button> */}
            {showPopupClip && (
              <div className="popup-clip-recut z-[2] w-[500px] bg-black-900 rounded-[14px] top-[100px] right-[20px] sm:right-[1%] sm:left-[1%]">
                <div className="header flex p-5 sm:w-[100%]">
                  <Heading size="headline_6" as="h1">
                    Créer un clip
                  </Heading>
                  <Img
                    onClick={handleButtonClipClick}
                    src="/images/img_close.svg"
                    alt="image"
                    className="h-[30px] sm:h-[30px] object-cover  cursor-pointer"
                  />
                </div>
                <div className="body">
                  <FilmClipsquence 
                    setDataTimeClipVideo={setDataTimeClipVideo}
                    setDisabledClipVideo={setDisabledClipVideo}
                    idsequence={video?.id} 
                    filmId={video?.filmId} 
                    title={video?.title}
                    timesequence={video?.timesequence}
                    maxDureeClips={video?.film.maxclip}
                    maxDureeSequence={video?.film.maxclipsequence}
                    className=" md:gap-[13px]" 
                    />
                </div>
              </div>
            )}
            <div className="my-auto flex h-max w-[100%] items-center md:relative md:flex-col">
              <div className="w-[100%]  bg-cover bg-no-repeat py-1 mt-[10px] md:h-auto md:w-full md:p-5 md:pt-1">
                <div className="flex flex-col items-start justify-between w-[100%] gap-[49px]">
                  <div className="container-xs md:p-5">
                    <div className="flex flex-col items-start gap-[58px] sm:gap-[29px]">
                      <Heading size="headline_1" as="h1">
                        {video?.title}
                      </Heading>
                      <div className="mb-[50px] mt-[30px]">
                        <Heading size="headline_6" as="p" className="mb-5">
                          {video?.desc}
                        </Heading>
                        <Heading size="subtitle_2" as="p" className="italic">
                          {video?.desc_en}
                        </Heading>
                      </div>
                      <Heading size="headline_3" as="h4">
                        Extraits issus du même film
                      </Heading>
                      <div className="grid w-[100%] grid-cols-4 md:grid-cols-2 gap-4 sm:grid-cols-1 sm:gap-12">
                        <Suspense fallback={<div>Loading feed...</div>}>
                          {videosByFilm?.map((sequence, index) => (
                            video?.id !== sequence.id && (
                              <FilmCardsquence 
                              key={"listtime" + index} 
                              scneomondain={sequence.title} 
                              filmId={sequence.filmId} 
                              leschoristes={sequence.subtitle} 
                              urlimage={sequence.urlimage} 
                              timesequence={sequence.timesequence}
                              idsequence={sequence.id}
                              producteur={sequence.producteur=="" || sequence.producteur ==null?sequence.film.producteur:sequence.producteur}
                              pseudofilm={sequence.film.pseudo}
                              className=" md:gap-[13px]" 
                            />
                            )
                          ))}
                        </Suspense>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer producteur={producteur}/>
    </>
  );
}