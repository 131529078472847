import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-lg",
};

const variants = {
  fill: {
    gray_900: "bg-gray-900",
    black_900_01: "bg-black-900_01 text-default-white",
    deep_purple_A200: "bg-deep_purple-a200 text-default-white",
    default_white: "bg-default-white text-black-900_01",
  },
};

const sizes = {
  xl: "h-[52px] pl-[19px] pr-[35px] text-base",
  lg: "h-[48px] pr-4",
  "2xl": "h-[60px] pl-8 pr-[17px] text-xl",
  sm: "h-[40px] pl-4 pr-[35px] text-base",
  xs: "h-[28px] pl-4 pr-[35px] text-base",
  md: "h-[48px] pl-4 pr-[35px] text-base",
};

const Input = React.forwardRef(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      children,
      label = "",
      prefix,
      suffix,
      onChange,
      shape,
      variant = "fill",
      size = "md",
      color = "default_white",
      ...restProps
    },
    ref,
  ) => {
    return (
      <label
        className={`${className} flex items-center justify-center cursor-text  ${(shape && shapes[shape]) || ""} ${variants[variant]?.[color] || variants[variant] || ""} ${sizes[size] || ""}`}
      >
        {!!label && label}
        {!!prefix && prefix}
        <input ref={ref} type={type} name={name} placeholder={placeholder} onChange={onChange} {...restProps} />
        {!!suffix && suffix}
      </label>
    );
  },
);
Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xl", "lg", "2xl", "sm", "xs", "md"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["gray_900", "black_900_01", "deep_purple_A200", "default_white"]),
};

export { Input };
