import axios from 'axios';
import apiClient from '../axios-interceptor';
import { Navigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL+"/sequences";



export async function getSequence(id) {
  try {
    const response = await apiClient.get(apiUrl+'/'+id);
    if(response.data){
      return response.data; 
    }
    
  } catch (error) {
    console.log('Error fetchData:', error);
    throw error;
  }
  return null;
}

export async function getSequencesByFilm(id) {
  try {
    const response = await apiClient.get(apiUrl+'/film/'+id);
    if(response.data){
      return response.data; 
    }
    
  } catch (error) {
    console.log('Error fetchData:', error);
    throw error;
  }
  return null;
}

export async function downloadSequences(idSequence,format) {
  try {
    return await apiClient.get(`${apiUrl}/download/${idSequence}/${format}`, {
      responseType: 'blob'
  });
    
  } catch (error) {
    console.log('Error fetchData:', error);
    throw error; 
  }
}

export async function downloadSequenceClip(idsequence, timeStart, timeEnd, outputName,format,partager) {
  if(partager==true){
    try {
      return await apiClient.post(`${apiUrl}/${idsequence}/clip`, {
        timeStart,
        timeEnd,
        outputName,
        format,
        partager,
    });
      
    } catch (error) {
      console.log('Error fetchData:', error);
      throw error;
    }
  }else{
    try {
      return await apiClient.post(`${apiUrl}/${idsequence}/clip`, {
        timeStart,
        timeEnd,
        outputName,
        format,
        partager,
    }, {
        responseType: 'blob', // Important for downloading the file
    });
      
    } catch (error) {
      console.log('Error fetchData:', error);
      throw error;
    }
  }
  
  return null;
}

