import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React, { useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { getMyProfil } from "services/userService";

const dropDownOptionsLangues = [
  { label: "Français", value: "fr" },
  { label: "Anglais", value: "en" }
];

const dropDownOptionsPays = [
  { label: "Allemagne", value: "Allemagne" },
  { label: "Autriche", value: "Autriche" },
  { label: "Belgique", value: "Belgique" },
  { label: "Bulgarie", value: "Bulgarie" },
  { label: "Chypre", value: "Chypre" },
  { label: "Croatie", value: "Croatie" },
  { label: "Danemark", value: "Danemark" },
  { label: "Espagne", value: "Espagne" },
  { label: "Estonie", value: "Estonie" },
  { label: "Finlande", value: "Finlande" },
  { label: "France", value: "France" },
  { label: "Grèce", value: "Grèce" },
  { label: "Hongrie", value: "Hongrie" },
  { label: "Irlande", value: "Irlande" },
  { label: "Italie", value: "Italie" },
  { label: "Lettonie", value: "Lettonie" },
  { label: "Lituanie", value: "Lituanie" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Malte", value: "Malte" },
  { label: "Pays-Bas", value: "Pays-Bas" },
  { label: "Pologne", value: "Pologne" },
  { label: "Portugal", value: "Portugal" },
  { label: "République tchèque", value: "République tchèque" },
  { label: "Roumanie", value: "Roumanie" },
  { label: "Slovaquie", value: "Slovaquie" },
  { label: "Slovénie", value: "Slovénie" },
  { label: "Suède", value: "Suède" }
];


export default function MesinfospersonnellesPage() {
  const [loading, setLoading] = useState(true); // Gère l'état de chargement
  const [error, setError] = useState(null); // Gère les erreurs potentielles
  const [dataUsers, setDataUsers] = useState(null);
  

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getMyProfil();
      setDataUsers(data.user);
      console.log(data);
    } catch (err) {
      setError("Erreur lors du chargement des statistiques.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    
    fetchData();
    // Pas besoin de cleanup function si elle n'est pas utilisée
  }, []); // Ce useEffect ne fait rien pour l'instant, mais peut être retiré si inutile

  if (loading) {
    return <div className="loader">Chargement en cours...</div>; // Affiche un loader pendant le chargement
  }

  if (error) {
    return <div className="error">{error}</div>; // Affiche un message d'erreur en cas d'échec de l'appel API
  }
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[66%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            
            <div className="flex flex-1 flex-col items-center justify-center rounded-lg bg-gray-900 p-[50px] md:p-5">
              <Img
                src={dataUsers.imageprofil}
                alt="image"
                className="h-[144px] w-[144px] rounded-[72px] object-cover"
              />
              <Text as="p" className="mt-[22px]">
                Modifier la photo de profil
              </Text>
              <div className="mt-[81px] flex flex-col gap-3.5 self-stretch">
                <div className="flex flex-col items-start gap-3 pt-1">
                  <Text as="p">Email</Text>
                  <Input
                    value={dataUsers.email}
                    shape="round"
                    name="input_one"
                    placeholder={`xxx@xxx.xx`}
                    className="self-stretch border border-solid border-white-dark_night-300 sm:pr-5"
                  />
                </div>
                <div className="flex flex-col items-start gap-3 pt-1">
                  <Text as="p">Compte TikTok</Text>
                  <Input
                    value={dataUsers.comptetiktok}
                    shape="round"
                    name="input_one"
                    placeholder={`tiktok.com/profil`}
                    className="self-stretch border border-solid border-white-dark_night-300 sm:pr-5"
                  />
                </div>
                <div className="flex flex-col items-start gap-3 pt-1">
                  <Text as="p">Compte Instagram</Text>
                  <Input
                    value={dataUsers.compteinstagram}
                    shape="round"
                    name="input_one"
                    placeholder={`instagram.com/profil`}
                    className="self-stretch border border-solid border-white-dark_night-300 sm:pr-5"
                  />
                </div>
                <div className="flex flex-col items-start gap-3 pt-1">
                  <Text as="p">Compte Facebook</Text>
                  <Input
                    value={dataUsers.comptefacebook}
                    shape="round"
                    name="input_one"
                    placeholder={`facebook.com/profil`}
                    className="self-stretch border border-solid border-white-dark_night-300 sm:pr-5"
                  />
                </div>
                <div className="flex flex-col items-start gap-[13px]">
                  <Text as="p">Pays</Text>
                  <SelectBox
                    value={dataUsers.pays}
                    shape="round"
                    indicator={
                      <Img src="images/img_arrowdown_black_900_01.svg" alt="arrow_down" className="h-[24px] w-[24px]" />
                    }
                    name="arrowdown"
                    placeholder={`Français`}
                    options={dropDownOptionsPays}
                    className="gap-px self-stretch border border-solid border-white-dark_night-300 sm:pr-5"
                  />
                </div>
                <div className="flex flex-col items-start gap-[13px]">
                  <Text as="p">Langues</Text>
                  <SelectBox
                    value={dataUsers.langue}
                    shape="round"
                    indicator={
                      <Img src="images/img_arrowdown_black_900_01.svg" alt="arrow_down" className="h-[24px] w-[24px]" />
                    }
                    name="arrowdown"
                    placeholder={`Français`}
                    options={dropDownOptionsLangues}
                    className="gap-px self-stretch border border-solid border-white-dark_night-300 sm:pr-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
