import React from "react";
import PropTypes from "prop-types";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-lg",
  circle: "rounded-[50%]",
};
const variants = {
  gradient: {
    purple_A700_deep_purple_A400: "bg-gradient text-default-white",
    deep_orange_600_deep_orange_A100: "bg-gradient1 text-default-white",
  },
  fill: {
    deep_purple_A200: "bg-deep_purple-a200 text-default-white",
    white_dark_night_100: "bg-white-dark_night-100 text-white-dark_night-300",
    default_white: "bg-default-white text-black-900_01",
    black_900: "bg-black-900 text-default-white",
    gray_900: "bg-gray-900 text-default-white",
    white_calendula_gold_500: "bg-white-calendula_gold-500 text-default-white",
    deep_orange_600: "bg-deep_orange-600 text-default-white",
    yellow_700: "bg-yellow-700",
  },
  outline: {
    default_white: "border-default-white border border-solid text-default-white",
  },
};
const sizes = {
  "7xl": "h-[79px] px-[35px] text-[31px]",
  lg: "h-[34px] px-[9px]",
  xs: "h-[23px] px-0.5",
  md: "h-[32px] pl-1.5 pr-[13px] text-xs",
  "5xl": "h-[55px] px-[9px]",
  "4xl": "h-[52px] px-3.5",
  "6xl": "h-[60px] px-[35px] text-base",
  "2xl": "h-[48px] px-3",
  xl: "h-[39px] pl-2.5 pr-4 text-base",
  "3xl": "h-[48px] px-[35px] text-base",
  sm: "h-[28px] px-[3px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "sm",
  color = "yellow_700",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round", "circle"]),
  size: PropTypes.oneOf(["7xl", "lg", "xs", "md", "5xl", "4xl", "6xl", "2xl", "xl", "3xl", "sm"]),
  variant: PropTypes.oneOf(["gradient", "fill", "outline"]),
  color: PropTypes.oneOf([
    "purple_A700_deep_purple_A400",
    "deep_orange_600_deep_orange_A100",
    "deep_purple_A200",
    "white_dark_night_100",
    "default_white",
    "black_900",
    "gray_900",
    "white_calendula_gold_500",
    "deep_orange_600",
    "yellow_700",
  ]),
};

export { Button };
