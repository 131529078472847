import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input, CheckBox } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React, { useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { getMyProfil } from "services/userService";
import { useParams } from "react-router-dom";
import { getallFilmsAndSequences, getUserById, updateUserAccess } from "services/adminServices";
import Switch from "react-switch";

export default function AdminEmbargoPageUser() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // Gère l'état de chargement
    const [error, setError] = useState(null); // Gère les erreurs potentielles
    const [dataUsers, setDataUsers] = useState(null);
    const [optionsfilm, setOptionsfilm] = useState([]);
    const [optionsSequence, setOptionsSequence] = useState([]);
    const [selectedOptionFilm, setSelectedOptionFilm] = useState(null);
    const [selectedOptionSequence, setSelectedOptionSequence] = useState(null);
    const [allFilmsOption, setAllFilmsOption] = useState([]);
    const [checkedAdmin, setCheckedAdmin] = useState(false);
    const [checkedAdminchallenges, setCheckedAdminchallenges] = useState(false);
    const [checkedAdminstatistiques, setCheckedAdminstatistiques] = useState(false);

    const handleSelectChangeFilm = (selectedOptionFilm) => {
        setSelectedOptionFilm(selectedOptionFilm);

        console.log(selectedOptionFilm);
        
        const selectedOption = selectedOptionFilm ? selectedOptionFilm.map(option => parseInt(option.value)) : [];
        
        // Met à jour les options de séquences en fonction des films sélectionnés
        const optionssequence = [];
        allFilmsOption.forEach(film => {
            if (selectedOption.includes(film.id)) {
                film.sequences.forEach(sequence => {
                    if (sequence.visible === false) {
                        optionssequence.push({
                            value: sequence.id,
                            label: sequence.title
                        });
                    }
                });
            }
        });
        setOptionsSequence(optionssequence);
        updateData(checkedAdmin,checkedAdminchallenges,checkedAdminstatistiques,selectedOptionSequence,selectedOptionFilm);
    };

    const handleSelectChangeSequence = (selectedOptionSequence) => {
        setSelectedOptionSequence(selectedOptionSequence);
        updateData(checkedAdmin,checkedAdminchallenges,checkedAdminstatistiques,selectedOptionSequence,selectedOptionFilm);
    };

    const handleChangeAdmin = () => {
        const ischeckedAdmin = checkedAdmin?false:true;
        setCheckedAdmin(ischeckedAdmin);
        updateData(ischeckedAdmin,checkedAdminchallenges,checkedAdminstatistiques,selectedOptionSequence,selectedOptionFilm);
    };

    const handleChangechallenges = () => {
        const ischeckedAdminchallenges = checkedAdminchallenges?false:true;
        setCheckedAdminchallenges(ischeckedAdminchallenges);
        updateData(checkedAdmin,ischeckedAdminchallenges,checkedAdminstatistiques,selectedOptionSequence,selectedOptionFilm);
        if(!ischeckedAdminchallenges){
            setSelectedOptionSequence([]);
            setSelectedOptionFilm([]);
            setOptionsSequence([]);
        }
        
    };

    const handleChangestatistiques = () => {
        const ischeckedAdminstatistiques = checkedAdminstatistiques?false:true;
        setCheckedAdminstatistiques(ischeckedAdminstatistiques);
        updateData(checkedAdmin,checkedAdminchallenges,ischeckedAdminstatistiques,selectedOptionSequence,selectedOptionFilm);
    };
    

    // Récupère la liste de tous les films et leurs séquences
    const fetchDataFilms = async (FilmssIds = "", SequencesIds = "") => {
        try {
            const allFilms = await getallFilmsAndSequences();
            setAllFilmsOption(allFilms);
    
            // Split FilmssIds and SequencesIds into arrays
            const arrayFilmssIds = FilmssIds.split(',').map(id => id.trim());
            const arraySequencesIds = SequencesIds.split(',').map(id => id.trim());
    
            // Initialize arrays for selected films and sequences
            const selectedFilms = [];
            const selectedSequences = [];
            const alloptionsSequence = [];
            // Check if FilmssIds is not empty
            if (arrayFilmssIds.length > 0 && arrayFilmssIds[0] !== "") {
                console.log("arrayFilmssIds", arrayFilmssIds);
    
                // Iterate over all films
                allFilms.forEach(film => {
                    console.log(film);
                    console.log(arrayFilmssIds.includes(film.id.toString()));
    
                    // Check if the film ID is in the array of film IDs
                    if (arrayFilmssIds.includes(film.id.toString())) {
                        selectedFilms.push({
                            value: film.id,
                            label: film.title
                        });
    
                        // Check if SequencesIds is not empty and contains sequences
                        if (arraySequencesIds.length > 0 && arraySequencesIds[0] !== "") {
                            film.sequences.forEach(sequence => {
                                if (arraySequencesIds.includes(sequence.id.toString())) {
                                    selectedSequences.push({
                                        value: sequence.id,
                                        label: sequence.title
                                    });
                                }
                                alloptionsSequence.push({
                                    value: sequence.id,
                                    label: sequence.title
                                });
                            });
                        }
                    }
                });
            }
    
            // Update the state with the selected films and sequences
            setSelectedOptionSequence(selectedSequences);
            setSelectedOptionFilm(selectedFilms);
            setOptionsSequence(alloptionsSequence);
    
            // Map over films and filter out undefined values
            const optionsfilm = allFilms
                .map(film => ({
                    value: film.id,
                    label: film.title
                }))
                .filter(option => option !== null); // Filter out null values
            setOptionsfilm(optionsfilm);
        } catch (err) {
            setError(err.message);
        }
    };
    

    const updateData = async (isadmin,isfilmsandsequnese,isafficherinstat,selectedOptionSequence,selectedOptionFilm) => {
        const selectedSequences = selectedOptionSequence ? selectedOptionSequence.map(option => parseInt(option.value)) : [];
        const selectedFilms = selectedOptionFilm ? selectedOptionFilm.map(option => parseInt(option.value)) : [];
        const updateData = {
            FilmssIds:selectedFilms.join(','),
            SequencesIds:selectedSequences.join(','),
            isadmin:isadmin?isadmin:false,
            isafficherinstat:isafficherinstat?isafficherinstat:false,
            isfilmsandsequnese:isfilmsandsequnese?isfilmsandsequnese:false,
          };
      
          try {
            const updatedUser = await updateUserAccess(id, updateData);
            console.log('User updated successfully:', updatedUser);
            const data = await getUserById(id);
            setDataUsers(data.user);
        } catch (err) {
            setError("Erreur lors du chargement des statistiques.");
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await getUserById(id);
            setDataUsers(data.user);
            setCheckedAdmin(data.user.isadmin);
            setCheckedAdminchallenges(data.user.isfilmsandsequnese);
            setCheckedAdminstatistiques(data.user.isafficherinstat);
            fetchDataFilms(data.user.access?.FilmssIds,data.user.access?.SequencesIds);
            console.log(data);
        } catch (err) {
            setError("Erreur lors du chargement des statistiques.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div className="loader">Chargement en cours...</div>; // Affiche un loader pendant le chargement
    }

    if (error) {
        return <div className="error">{error}</div>; // Affiche un message d'erreur en cas d'échec de l'appel API
    }

    return (
        <>
            <Helmet>
                <title>ReCut</title>
                <meta
                    name="description"
                    content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut."
                />
            </Helmet>
            <div className="relative w-full">
                <div className="flex flex-col z-[5] gap-[27px]">
                    <AdminGestionPageDistributeurNavbar />
                    <div className="ml-[54px] flex w-[66%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
                        <Sidebar />

                        <div className="flex flex-1 flex-col items-center justify-center rounded-lg bg-gray-900 p-[50px] md:p-5">
                            <Img
                                src={dataUsers.imageprofil && dataUsers.imageprofil!=""?dataUsers.imageprofil:"/images/icons/profile.png"}
                                alt="image"
                                className="h-[144px] w-[144px] rounded-[72px] object-cover"
                            />
                            <Text as="p" className="mt-[22px]">
                                {dataUsers.email}
                            </Text>
                            
                            <div className="mt-[30px] flex flex-col gap-3.5 self-stretch">
                                <div className="flex items-start gap-3 pt-1">
                                    <Text as="p" className="mt-[22px]">
                                        NB: Pour appliquer cette modification à l'utilisateur, il faut que l'utilisateur concerné se déconnecte et se reconnecte une autre fois.
                                    </Text>
                                </div>
                                <div className="flex items-start gap-3 pt-1">
                                    <Switch onChange={handleChangeAdmin} checked={checkedAdmin} /> 
                                    <Text as="p" className="mt-[5px]">
                                        Activer le mode administrateur.
                                    </Text>
                                </div>

                                <div className="flex items-start gap-3 pt-1">
                                    <Switch onChange={handleChangestatistiques} checked={checkedAdminstatistiques} /> 
                                    <Text as="p" className="mt-[5px]">
                                        N'apparaît pas dans les statistiques.
                                    </Text>
                                </div>

                                <div className="flex items-start gap-3 pt-1">
                                    <Switch onChange={handleChangechallenges} checked={checkedAdminchallenges} /> 
                                    <Text as="p" className="mt-[5px]">
                                        Afficher les challenges non visibles.
                                    </Text>
                                </div>
                                
                                {checkedAdminchallenges && (
                                    <>
                                        <div className="flex flex-col items-start gap-3 pt-1">
                                            <SelectBox
                                                options={optionsfilm}
                                                value={selectedOptionFilm}
                                                onChange={handleSelectChangeFilm}
                                                isSearchable={true}
                                                isMulti={true}
                                                placeholder="Film..."
                                                shape="round"
                                                size="sm"
                                                variant="fill"
                                                className="min-w-[100%] mr-[20px]"
                                            />
                                        </div>
                                        {optionsSequence.length > 0 && (
                                            <div className="flex flex-col items-start gap-3 pt-1">
                                                <SelectBox
                                                    options={optionsSequence}
                                                    value={selectedOptionSequence}
                                                    onChange={handleSelectChangeSequence}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    placeholder="Séquence..."
                                                    shape="round"
                                                    size="sm"
                                                    variant="fill"
                                                    className="min-w-[100%]"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
