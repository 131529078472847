import { Helmet } from "react-helmet";
import { SelectBox, Input, Heading } from "../../components";
import Sidebar from "../../components/Sidebar";
import React, { useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { getallFilmsAndSequences, getAllUsersSequencesLogs, getUserSequencesLogs } from "services/adminServices";
import Footer from "components/Footer";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate, getCurrentDate, getCurrentDateTime } from "services/confServices";

export default function AdminStatistiquePageUserLogs() {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataSequences, setDataSequences] = useState([]);
  const [filmId, setFilmId] = useState(null);
  const [sequenceId, setSequenceId] = useState(null);
  const [isAllUsers, setIsAllUsers] = useState(false);
  const location = useLocation();
  const [datalogs, setDatalogs] = useState(null);
  const [selectedOptionFilm, setSelectedOptionFilm] = useState(null);
  const [selectedOptionSequence, setSelectedOptionSequence] = useState(null);
  const [allFilmsOption, setAllFilmsOption] = useState([]);
  const [optionsfilm, setOptionsfilm] = useState([]);
  const [optionsSequence, setOptionsSequence] = useState([]);

  // Ajout des états pour la plage de dates
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());

  // Gère le changement de la date de début et filtre les données
  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    filterData(datalogs, newStartDate, endDate, selectedOptionFilm, selectedOptionSequence);
  };

  // Gère le changement de la date de fin et filtre les données
  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    filterData(datalogs, startDate, newEndDate, selectedOptionFilm, selectedOptionSequence);
  };

  // Gère la sélection des séquences et applique le filtre en fonction des séquences choisies
  const handleSelectChangeSequence = (selectedOptionSequence) => {
    setSelectedOptionSequence(selectedOptionSequence);

    const selectedOption = selectedOptionSequence ? selectedOptionSequence.map(option => parseInt(option.value)) : [];
    const selectedOptionfilm = selectedOptionFilm ? selectedOptionFilm.map(option => parseInt(option.value)) : [];

    filterData(datalogs, startDate, endDate, selectedOptionfilm, selectedOption);
  };

  // Gère la sélection des films et met à jour les séquences disponibles en fonction des films choisis
  const handleSelectChangeFilm = (selectedOptionFilm) => {
    setSelectedOptionFilm(selectedOptionFilm);

    const selectedOption = selectedOptionFilm ? selectedOptionFilm.map(option => parseInt(option.value)) : [];
    const selectedOptionsequence = selectedOptionSequence ? selectedOptionSequence.map(option => parseInt(option.value)) : [];

    const optionssequence = [];
    setSelectedOptionSequence(null);  // Réinitialiser les séquences sélectionnées

    // Met à jour les options de séquences en fonction des films sélectionnés
    allFilmsOption.forEach(film => {
      if (selectedOption.includes(film.id)) {
        film.sequences.forEach(sequence => {
          optionssequence.push({
            value: sequence.id,
            label: sequence.title
          });
        });
      }
    });
    setOptionsSequence(optionssequence);

    // Appliquer le filtre avec les nouvelles valeurs
    filterData(datalogs, startDate, endDate, selectedOption, selectedOptionsequence);
  };

  // Filtre les données en fonction de la plage de dates, des films et des séquences sélectionnés
  const filterData = (datalogs, startDate = null, endDate = null, selectedOptionFilms = [], selectedOptionSequences = []) => {
    if (!datalogs) return; // Vérification si datalogs est null ou undefined
  
    let filteredData = datalogs.map(data => {
      const filteredSequences = data.UserConnectionSequences.filter(seq => {
        const sequenceMatches = Array.isArray(selectedOptionSequences) && selectedOptionSequences.length
          ? selectedOptionSequences.includes(seq.Sequence.id)
          : true;
        const filmMatches = Array.isArray(selectedOptionFilms) && selectedOptionFilms.length
          ? selectedOptionFilms.includes(seq.Sequence.filmId)
          : true;
  
        return sequenceMatches && filmMatches;
      });
  
      return {
        ...data,
        UserConnectionSequences: filteredSequences
      };
    }).filter(data => {
      // Retirer les connexions sans séquences correspondantes
      if (Array.isArray(selectedOptionFilms) && selectedOptionFilms.length > 0 ||
          Array.isArray(selectedOptionSequences) && selectedOptionSequences.length > 0) {
        return data.nbClip !== 0 && data.UserConnectionSequences.length > 0;
      }
      return true;
    });
  
    // Filtre par date
    if (startDate && endDate) {
      // Si startDate est égal à endDate, afficher uniquement les données dont createdAt correspond exactement à startDate
      if (startDate === endDate) {
        filteredData = filteredData.filter(data => {
          const createdAtDate = data.createdAt.split('(')[0].replace(/\//g, '-'); // 'YYYY/MM/DD' => 'YYYY-MM-DD'
          const date1 = new Date(createdAtDate);
          const start = new Date(startDate);
  
          // Comparer uniquement si la date est exactement égale à startDate
          return date1.getFullYear() === start.getFullYear() &&
                 date1.getMonth() === start.getMonth() &&
                 date1.getDate() === start.getDate();
        });
      } else {
        // Si startDate et endDate sont différents, filtrer par plage de dates
        filteredData = filteredData.filter(data => {
          const createdAtDate = data.createdAt.split('(')[0].replace(/\//g, '-'); // 'YYYY/MM/DD' => 'YYYY-MM-DD'
          const date1 = new Date(createdAtDate);
          const start = new Date(startDate);
          const end = new Date(endDate);
  
          // Comparer si la date est dans la plage définie par startDate et endDate
          return date1.getFullYear() >= start.getFullYear() && date1.getFullYear() <= end.getFullYear() &&
          date1.getMonth() >= start.getMonth() && date1.getMonth() <= end.getMonth() &&
          date1.getDate() >= start.getDate() && date1.getDate() <= end.getDate();

        });
      }
    }
  
    // Préparer les données filtrées pour l'affichage
    preparData(filteredData);
  };
  
  
  // Récupère les logs des séquences des utilisateurs et les informations des films
  const fetchData = async () => {
    try {
      let datalogs = null;
      if (id && id > 0) {
        datalogs = await getUserSequencesLogs(id);
        setIsAllUsers(false);
      } else {
        datalogs = await getAllUsersSequencesLogs();
        setIsAllUsers(true);
      }

      const allFilms = await getallFilmsAndSequences();
      setDatalogs(datalogs);
      filterData(datalogs, startDate, endDate);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const getPartagerFormat = (formatPartager) => {
    if(formatPartager == "vertical") return "Vertical";
    if(formatPartager == "horizontal") return "Horizontal";
    if(formatPartager == "square") return "Carre";   
    if(formatPartager == "original") return "Original";
  };


  // Prépare les données filtrées pour être affichées dans le tableau
  const preparData = (datalogs) => {
    try {
      const tampdata = [];
      datalogs.map((connection) => {
        let connectionText = "Aucun clip téléchargé.";
        let connectionData = [];

        if (connection.UserConnectionSequences && connection.UserConnectionSequences.length>0) {
          connectionText = "";
          connection.UserConnectionSequences.map((connectionSequences,index) => {
            const formatSequence = getPartagerFormat(connectionSequences.UserSequence.formatSequence);
            connectionText += `${index+1}-Challenges: ${connectionSequences.UserSequence.Sequence.subtitle} / ${connectionSequences.UserSequence.Sequence.title} ${connectionSequences.UserSequence.durationSequence} secondes (${formatSequence}), du ${connectionSequences.UserSequence.timeStart} au ${connectionSequences.UserSequence.timeEnd} au ${formatDate(connectionSequences.UserSequence.createdAt)}<br/><br/>`;

            connectionData.push({
              sequenceId: connectionSequences.UserSequence.Sequence.id,
              filmId: connectionSequences.UserSequence.Sequence.filmId,
              filmName: connectionSequences.UserSequence.Sequence.subtitle,
              sequence: connectionSequences.UserSequence.Sequence.title,
              duration: connectionSequences.UserSequence.durationSequence,
              timeStart: connectionSequences.UserSequence.timeStart,
              timeEnd: connectionSequences.UserSequence.timeEnd,
              formatSequence:formatSequence,
              createdAt: formatDate(connectionSequences.UserSequence.createdAt),
            });
          });
        }

        tampdata.push({
          UserId: connection.UserId,
          userEmail: connection.User?.email,
          nbClip: connectionData.length,
          connectionText: connectionText,
          connectionData: connectionData,
          createdAt: formatDate(connection.createdAt),
        });
      });
      setDataSequences(tampdata);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Récupère la liste de tous les films et leurs séquences
  const fetchDataFilms = async () => {
    try {
      const allFilms = await getallFilmsAndSequences();
      setAllFilmsOption(allFilms);

      const optionsfilm = allFilms.map(film => ({
        value: film.id,
        label: film.title,
      }));
      setOptionsfilm(optionsfilm);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setFilmId(params.get('film') ? params.get('film') : null);
    setSequenceId(params.get('sequence') ? params.get('sequence') : null);
    fetchData();
    fetchDataFilms();
    window.scrollTo(0, 0);
  }, []);

  // Fonction pour exporter les données sous forme de CSV
  const exportCSVUser = () => {
    const csvRows = [];
    const headers = ['Created At' , 'Nb clip', 'User', 'Challenge','Sequence', 'Duree du clip', 'Start clip', 'End clip','Format clip', 'Created At Clip'];
    csvRows.push(headers.join(';'));

    // Ajouter les lignes des données
    dataSequences.forEach(connection => {
      if (connection.connectionData.length > 0) {
        connection.connectionData.forEach((connectionSequences, index) => {
          const row = [
            index === 0 ? connection.createdAt : "",
            index === 0 ? connection.nbClip : "",
            index === 0 ? connection.userEmail : "",
            connectionSequences.filmName?connectionSequences.filmName:"",
            connectionSequences.sequence,
            connectionSequences.duration,
            connectionSequences.timeStart,
            connectionSequences.timeEnd,
            connectionSequences.formatSequence,
            connectionSequences.createdAt,
          ];
          csvRows.push(row.join(';'));
        });
      } else {
        csvRows.push([connection.createdAt, connection.nbClip, connection.userEmail, "","", "", "", "", "", ""].join(';'));
      }
    });

    // Créer un Blob à partir des données CSV et télécharger le fichier
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    const current_datetime = getCurrentDateTime();
    a.setAttribute('download', `U-users_statistiques-${current_datetime}.csv`);
    a.click();
  };

  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[100%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            <div className="">
              { 
                sequenceId
                  ? <Link to={`/admin/statistique/film/${filmId}/sequence/${sequenceId}`} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques Sequence</Heading></Link>
                  : filmId
                  ? <Link to={`/admin/statistique/film/${filmId}`} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques Film</Heading></Link>
                  : <Link to={"/admin/statistiques"} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques</Heading></Link>
              }
              <div className="flex w-[100%] flex-col items-end gap-[55px] md:w-full md:p-5 sm:gap-[27px]">
                <div className="flex flex-col gap-[37px] self-stretch">
                  <div className="container-xs">
                    <div className="flex">
                      {/* Champ pour la date de début */}
                      <Input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        placeholder="Date de début..."
                        shape="round"
                        size="sm"
                        variant="fill"
                        color="deep_purple_A200"
                        className="min-w-[200px] mr-[20px]"
                      />
                      {/* Champ pour la date de fin */}
                      <Input
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        placeholder="Date de fin..."
                        shape="round"
                        size="sm"
                        variant="fill"
                        color="deep_purple_A200"
                        className="min-w-[200px] mr-[20px]"
                      />
                      <SelectBox
                        options={optionsfilm}
                        value={selectedOptionFilm}
                        onChange={handleSelectChangeFilm}
                        isSearchable={true}
                        isMulti={true}
                        placeholder="Film..."
                        shape="round"
                        size="sm"
                        variant="fill"
                        color="gray_900"
                        className="min-w-[200px] mr-[20px]"
                      />
                      {optionsSequence.length > 0 && 
                        <SelectBox
                          options={optionsSequence}
                          value={selectedOptionSequence}
                          onChange={handleSelectChangeSequence}
                          isSearchable={true}
                          isMulti={true}
                          placeholder="Séquence..."
                          shape="round"
                          size="sm"
                          variant="fill"
                          color="gray_900"
                          className="min-w-[200px]"
                        />
                      }
                    </div>
                    <div className="self-stretch mb-5">
                      <DataTable 
                        value={dataSequences} 
                        paginator 
                        loading={loading}
                        filters={dataSequences} 
                        filterDisplay="row"
                        rows={10} 
                        sortField="sequenceCount" 
                        sortOrder={-1}
                        className="p-datatable-striped"
                        globalFilterFields={['filmName', 'sequenceName', 'durationClip']}
                        style={{ backgroundColor: 'transparent', color: '#fff' }}
                      >
                        <Column field="createdAt" header="Created At" body={(rowData) => `${rowData.createdAt}`} sortable />
                        <Column field="nbClip" header="N° Clip" body={(rowData) => `${rowData.nbClip}`} sortable />
                        {isAllUsers &&  <Column field="userEmail" header="User" body={(rowData) => `${rowData.userEmail}`} sortable />}
                        <Column field="connectionText" header="Logs" body={(rowData) => <div dangerouslySetInnerHTML={{ __html: rowData.connectionText }} />} sortable />
                      </DataTable>
                    </div>
                    <div className="mb-3 text-center">
                      <button 
                        onClick={exportCSVUser} 
                        className="btn-clip"
                        style={{ padding: "10px 20px", color: "white", borderRadius: "5px", cursor: "pointer" }}
                      >
                        Télécharger CSV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
