export const formatDate = (date) => {
    if (!date) {
      return 'N/A'; // Handle null or undefined dates
    }
    
    const d = new Date(date);
  
    // Check if the date is valid
    if (isNaN(d.getTime())) {
      return 'Invalid date'; // Return a fallback value instead of throwing an error
    }
  
    // Extract date components
    const year = String(d.getFullYear());
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');
  
    // Return formatted date as 'YY/MM/DD HH:mm:ss'
    return `${year}/${month}/${day}(${hours}:${minutes}:${seconds})`;
  };
  
  
export const getCurrentDateTime = () => {
    const now = new Date();
  
    // Format the date and time components
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    // Combine to get the final formatted string
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};