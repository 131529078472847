import { Link } from "react-router-dom";
import { Text, Heading, Button, Img } from "./..";
import React, { useState } from "react";
import { isLogin } from "services/authServices";

export default function HomeColumntime({
  videos,
  ...props
}) {
  const isSecondRow = (index) => Math.floor(index / 4) === 1;
  const [durations, setDurations] = useState({});
  const appUrl = process.env.REACT_APP_URL;
  const convertToSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[\s\W-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };
  return (
    <>
      {Array.isArray(videos) && videos.length > 0 ? (
        <div {...props} className={`${props.className} container-xs mb-[90px]`}>
          <div className="grid grid-cols-4 gap-4 gap-[20px] mt-[20px] sm:grid-cols-1 sm:gap-12">
            {videos.map((video, index) => (
              <div
                key={index}
                className={`relative w-full p-[15px] pl-[0px]  pt-[0px] ${
                  [0, 2, 5, 7].includes(index)
                    ? "h-[360px] sm:h-[250px] sm:mt-[10px]"
                    : "h-[200px] sm:h-[250px] sm:mt-[10px]"
                } ${
                  [5, 7].includes(index) ? "mt-[-145px] sm:mt-[10px]" : ""
                }`}
                style={
                  isSecondRow(index)
                    ? {
                        top: window.innerWidth >= 640 ? "46px" : "0px",
                        position: "relative",
                      }
                    : {}
                }
              >
                <div className="videofilmrecut h-full w-full overflow-hidden rounded-[10px] sm:rounded-[5px] flex justify-center">
                  <Link
                    to={
                      isLogin()
                        ? "/" +
                          video.film.producteur +
                          "/" +
                          video.film.pseudo +
                          "/" +
                          convertToSlug(video.title) +
                          "/" +
                          video.id
                        : "/connexionseaux?sequence=" + video.id
                    }
                    className="masquesurvideo"
                    style={{
                      height: [0, 2, 5, 7].includes(index) ? "300px" : "170px",
                    }}
                  ></Link>
                  <Img
                    src={appUrl + video.urlimage}
                    alt="image"
                    className="w-full h-full object-cover rounded-[20px] sm:rounded-[3.5px]"
                  />
                </div>
                <div className="absolute right-[25px] top-[10px] flex w-[41%] justify-end gap-[5px]">
                  <Text
                    as="p"
                    className="flex items-center justify-center text-[15px] rounded bg-black-900_bf px-[5px] py-1 sm:px-2.5 sm:py-0.5 h-[25px]"
                  >
                    {durations[index] || video.timesequence}
                  </Text>
                </div>
                <div className="flex w-full items-start justify-top gap-[15px] blockinfovideo sm:gap-[7.5px]">
                  <Img
                    src={
                      "images/logo-" +
                      (video.producteur == "" || video.producteur == null
                        ? video.film.producteur
                        : video.producteur) +
                      "-sequences.png"
                    }
                    className="w-[30px] !rounded-[14px]"
                  />
                  <div className="flex flex-1 flex-col items-start gap-2 sm:gap-1 w-[80%]">
                    <Heading
                      size="subtitle_3"
                      as="h3"
                      className="sm:text-sm limit-texte"
                    >
                      {video.title}
                    </Heading>
                    <Link
                      to={
                        "/" + video.film.producteur + "/" + video.film.pseudo
                      }
                    >
                      <Text
                        as="p"
                        className="!text-gray-500 sm:text-xs limit-texte"
                      >
                        {video.subtitle}
                      </Text>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
