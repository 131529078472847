import { Img } from "./..";
import React, { useState } from "react";
import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";
import { Link, Navigate, useLocation } from "react-router-dom";
import { isAdmin, isLogin } from "services/authServices";
export default function Sidebar11({ ...props }) {
  const [collapsed, setCollapsed] = React.useState(false);
  const [toNavigate, setToNavigate] = useState(false); 
  const location = useLocation();

  const handleLogout = () => {
    localStorage.clear();
    setToNavigate(true);
  };

  const isActive = (path) => location.pathname.includes(path) ? "active" : "";

  if (toNavigate) return <Navigate to="/connexionseaux" />;

  return (
    <Sidebar
      {...props}
      width="409px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
      className={`${props.className} flex flex-col h-screen top-0 !sticky overflow-auto md:hidden`}
    >
      <Menu
        menuItemStyles={{
          button: {
            padding: "14px 38px",
            gap: "20px",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "16px",
            borderRadius: "8px",
            [`&:hover, &.ps-active`]: { backgroundColor: "#212121 !important" },
          },
        }}
        rootStyles={{ ["&>ul"]: { gap: "0.03px" } }}
        className="flex w-full flex-col self-stretch md:hidden"
      >
        {isAdmin()&&
        <>
        <Link to="/admin/statistiques" className={`${isActive("/admin/statistique")} menu-recut`}>
          <MenuItem icon={<Img src="/images/img_statistics.svg" alt="cart" className="h-[26px] w-[23px]" />}>
            Mes Statistiques
          </MenuItem>
        </Link>
        <Link to="/admin/embargo" className={`${isActive("/admin/embargo")} menu-recut`}>
          <MenuItem icon={<Img src="/images/img_settings_default_white.svg" alt="cart" className="h-[26px] w-[23px]" />}>
          Gestion d'un embargo
          </MenuItem>
        </Link>
        </>
        
        }
        <Link to="/messsequencessix" className={`${isActive("/messsequencessix")} menu-recut`}>
          <MenuItem icon={<Img src="/images/img_film.svg" alt="film" className="h-[26px] w-[23px] activer" />}>
            Mes séquences
          </MenuItem>
        </Link>
        
        {/* <Link to="/mescommandesone" className={isActive("/mescommandesone")}>
          <MenuItem icon={<Img src="/images/img_cart_default_white_26x23.svg" alt="cart" className="h-[26px] w-[23px]" />}>
            Mes commandes (0) 
          </MenuItem>
        </Link>
        <Link to="/wallet" className={isActive("/wallet")}>
          <MenuItem icon={<Img src="/images/img_film.svg" alt="film" className="h-[26px] w-[23px]" />}>
            Wallet 
          </MenuItem>
        </Link>
        <Link to="/mesinfospersonnelles" className={isActive("/mesinfospersonnelles")}>
          <MenuItem icon={<Img src="/images/img_lock.svg" alt="lock" className="h-[23px] w-[23px]" />}>
            Mes informations personnelles 
          </MenuItem>
        </Link>
        <Link to="/prfrencesde" className={isActive("/prfrencesde")}>
          <MenuItem icon={<Img src="/images/img_settings_default_white.svg" alt="settings" className="h-[23px] w-[23px]" />}>
            Préférences de contenu 
          </MenuItem>
        </Link> */}
        {/* <Link to="/mesinfospersonnelles" className={isActive("/mesinfospersonnelles")}>
          <MenuItem icon={<Img src="/images/img_lock.svg" alt="lock" className="h-[23px] w-[23px]" />}>
              Profil
          </MenuItem>
        </Link> */}
        <Link to="/mentions-legales" className={`${isActive("/mentions-legales")} menu-recut`}>
          <MenuItem icon={<Img src="/images/img_documenttext.svg" alt="image" className="h-[23px] w-[23px]" />}>
            Mentions légales
          </MenuItem>
        </Link>
        <MenuItem icon={<Img src="/images/img_logout.svg" alt="image" className="h-[23px] w-[23px] menu-recut" />} onClick={handleLogout}>
          Déconnexion
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}
