import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const PrivateRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log("Aucun token trouvé dans le localStorage. Redirection vers la page de connexion.");
      return <Navigate to="/connexionseaux" state={{ from: location }} />;
    }
  }

  return children ? children : <Outlet />;
};

export default PrivateRoute;
