import { Heading, Img, Text } from "./..";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer({ producteur="",challenge="",...props }) {
  return (
    <footer {...props} className={`${props.className} flex justify-center  items-end py-[15px] mt-[20px] text-white`} style={{"background":"linear-gradient(transparent, black)"}}>
      <div className="container-xs mt-[17px] flex justify-center md:p-5">
        <div className="flex w-full items-start justify-between gap-5 sm:flex-col">
          <div className="flex items-start justify-start flex-col">
            <Link to="/">
                <Img
                    alt="e5"
                    className="w-[70px] mt-[-20px]"
                    src={"/images/logo-recut-white.png"}
                />
            </Link>
            <Text as="p" className="max-w-max">Téléchargez légalement des images<br/> protégées par le droit d’auteur.</Text>
            <div className="flex w-full mt-[5px]">
              <a href="https://www.instagram.com/recut_fr" target="_blank" rel="noreferrer" >
                <Img
                  alt="e5"
                  className="w-[30px] mr-[5px]"
                  src={"/images/icons/instagram.png"}
                />
              </a>
              <a href="https://www.tiktok.com/@recut_app" target="_blank" rel="noreferrer" >
                <Img
                  alt="e5"
                  className="w-[30px] mr-[5px]"
                  src={"/images/icons/tiktok.png"}
                />
              </a>
              <a href="https://www.linkedin.com/company/recutinc" target="_blank" rel="noreferrer" >
                <Img
                  alt="e5"
                  className="w-[30px] mr-[5px]"
                  src={"/images/icons/linkedin.png"}
                />
              </a>
            </div>
          </div>
          <div className="flex items-start justify-start flex-col">
            {/* {producteur!="" && 
              <Link to={"/conditions-generales-utilisation?challenge="+challenge} className="text-white">
                  <Text as="p" className="max-w-max">Conditions Générales d'utilisation</Text>
              </Link>
            } */}
            {/* {producteur=="recut" && 
              <Link to={"/conditions-generales-utilisation-"+producteur+"?challenge="+challenge} className="text-white">
                  <Text as="p" className="max-w-max">Conditions Générales d'utilisation</Text>
              </Link>
            } */}
            
            <Heading size="headline_6" as="h6" className="mb-[20px] sm:mb-[5px]">
              À propos de Re[ut
            </Heading>
            <a href="https://forms.gle/rDifZo8rXEUMHaLE7" target="_blank" className="text-white mb-[20px] sm:mb-[5px]">
                <Text as="p" className="max-w-max">Centre de support</Text>
            </a>
            <a href="https://recut.notion.site/Re-ut-Peace-in-Creativity-1161ce54c21f80caa389d8f4519bc9f2" target="_blank" className="text-white mb-[20px] sm:mb-[5px]">
                <Text as="p" className="max-w-max">Découvrir Re[ut</Text>
            </a>
          </div>  
          <div className="flex items-start justify-start flex-col">
            <Heading size="headline_6" as="h6" className="mb-[20px] sm:mb-[5px]">
              Informations légales
            </Heading>
            <Link to="/privacy-policy" className="text-white mb-[20px] sm:mb-[5px]">
                <Text as="p" className="max-w-max">Politique de Confidentialité</Text> 
            </Link>
            <Link to="/mentions-legales" className="text-white mb-[20px] sm:mb-[5px]">
                <Text as="p" className="max-w-max">Mentions légales</Text> 
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}



// import { Img, Text } from "./..";
// import React from "react";
// import { Link } from "react-router-dom";

// export default function Footer({ producteur="",...props }) {
//   return (
//     <footer {...props} className={`${props.className} flex justify-center  items-end py-[15px] mt-[20px] text-white`} style={{"background":"linear-gradient(transparent, black)"}}>
//       <div className="container-xs mt-[17px] flex justify-center md:p-5">
//         <div className="flex w-full items-center justify-between gap-5 sm:flex-col">
//           <Link to="/">
//             <Img
//                 alt="e5"
//                 className="w-[70px]"
//                 src={"/images/logo-recut-white.png"}
//             />
//           </Link>
//           {producteur=="pathe" && 
//               <Link to="/conditions-generales-utilisation" className="text-white">
//                   <Text as="p" className="max-w-max">Conditions Générales d'utilisation</Text>
//               </Link>
//             }
//             {producteur=="recut" && 
//               <Link to={"/conditions-generales-utilisation-"+producteur} className="text-white">
//                   <Text as="p" className="max-w-max">Conditions Générales d'utilisation</Text>
//               </Link>
//             }
//          <Link to="/privacy-policy" className="text-white">
//                 <Text as="p" className="max-w-max">Politique de Confidentialité</Text> 
//             </Link>
//             <a  href="https://www.tiktok.com/@recut_app" className="text-white">
//             <Text as="p" className="max-w-max">TikTok</Text> 
            
//             </a>
          
         
//         </div>
//       </div>
//     </footer>
//   );
// }
