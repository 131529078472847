import React, { createContext, useContext, useState, useEffect } from 'react';
// Création du contexte
const AuthContext = createContext();

// Fournisseur de contexte
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      // Logique pour vérifier le token (facultatif, par exemple décoder et vérifier l'expiration)
      console.log("Token trouvé dans le localStorage. Mise à jour de l'état de connexion.");
      setIsLoggedIn(false);
      console.log("isLoggedIn",isLoggedIn);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte Auth
export const useAuth = () => {
  return useContext(AuthContext);
};
