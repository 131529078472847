import { Link } from "react-router-dom";
import { Text, Img, Button, Heading } from "..";
import React, { useEffect, useState } from "react";
import { downloadSequenceClip } from "services/sequencesServices";
const appUrl = process.env.REACT_APP_URL;

export default function MesSequenceUser({
  download = true,
  scenetext = "",
  movietext = "",
  pricetext = "",
  timetext = "0:0",
  sequencedata,
  ...props
}) {
  const [sequence, setSequence] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    console.log(sequencedata);
    setSequence(sequencedata.Sequence);
  }, [sequencedata]);

  const handleButtonClick = async () => {
    setShowPopup(!showPopup);
  };
  const handleMouseLeave = () => {
    //setShowPopup(false);
  };
  const handleButtonDownloadClick = async (format) => {
      
    setIsLoading(true);
    setShowPopup(false);
   
    try {
        const response = await downloadSequenceClip(
          sequencedata.SequenceId, 
          sequencedata.timeStart, 
          sequencedata.timeEnd, 
          sequence.subtitle,
          format);
          
        const blob = new Blob([response.data], { type: 'video/mp4' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${sequence.subtitle}_${format}.mp4`;
        link.click();
    } catch (error) {
        console.error('Error downloading video clip:', error);
        if (error.response) {
            if (error.response.status === 400 && error.response.data) {
                console.error('Error 400:', error.response.data);
            } else {
                console.error('Server responded with error:', error.response.data);
            }
        } else if (error.request) {
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
    } finally {
        setIsLoading(false);
    }
  };
  // const padTime = (time) => {
  //   let [minutes, seconds] = time.split(':');
  //   if (minutes.length < 2) minutes = '0' + minutes;
  //   if (seconds.length < 2) seconds = '0' + seconds;
  //   return `${minutes}:${seconds}`;
  // };
  return (
    <div {...props} className={`${props.className} h-[auto] w-[100%] md:w-[100%] flex relative md:flex-wrap mb-[10px] bg-gray-900 rounded-[15px]`}>
      {isLoading && (
        <div className="loading-recut">
          <Img
            src="/images/loading.gif"
            alt="Loading"
            className="h-[200px] object-cover cursor-pointer"
          />
        </div>
      )}
      <div className="relative m-auto m-[10px] h-[auto] w-[35%] md:w-[100%] md:h-auto md:pb-5">
        <Img
          src={appUrl+sequence.urlimage}
          alt="image"
          className=" w-full h-[170px] rounded-[7px] object-cover"
        />
        
        <div className="absolute left-[-5px] top-[-5px] m-auto flex w-[50px]">
          <Img
            src={appUrl+"public/films/"+sequence?.film?.id+"-affiche.jpg"}
            alt="image"
            className="w-[50px] rounded-[4px] object-contain"
          />
          {/* <Text as="p" className="flex items-center justify-center md:w-[100%] rounded bg-black-900_bf px-[5px] py-1">
            {sequence.timeStart}
          </Text>
          <Text as="p" className="flex items-center justify-center md:w-[100%] ml-[5px] rounded bg-black-900_bf px-[5px] py-1">
            {sequence.timeEnd}
          </Text> */}
        </div>
      </div>
      <div className="m-auto flex w-full items-start justify-between pl-[30px]  sm:relative sm:flex-col sm:gap-5 sm:p-5">
        <div className="mb-10 flex flex-col items-start gap-5 sm:mb-0">
          <Link to={"/"+sequence.film?.pseudo} className="flex items-center justify-center">
            
            <Img
              src={"/images/logo-"+(sequence.producteur=="" || sequence.producteur ==null?sequence.film?.producteur:sequence.producteur)+"-sequences.png"}
              alt="image"
              className="w-[30px] rounded-[7px] object-contain mr-[10px]"
            />
            <Heading size="subtitle_2" as="h6">
              {sequence.subtitle}
            </Heading>
          </Link>
          <Link to={"/show/"+sequence.id}>
            <Heading size="headline_6" as="h3">
            {sequence.title}
            </Heading>
          </Link>
          
          <Heading size="subtitle_2" as="h6">
          Durée de la séquence {sequencedata.durationSequence} secondes <br/><br/>du {sequencedata.timeStart} au {sequencedata.timeEnd}
          </Heading>
        </div>
        {download && 
        <Button
          onClick={handleButtonClick}
          color="gray_900"
          size="4xl"
          shape="round"
          className="w-[52px] mr-[15px] border border-solid border-deep_purple-a200_01"
        >
          <Img src="/images/download.svg" />
        </Button>
        }
        
        {showPopup && (
          <div 
            className="absolute top-[-50px] w-[300px] right-[80px] mt-12 ml-12 p-4 bg-white shadow-lg rounded-lg z-50 bg-black-900"
            onMouseLeave={handleMouseLeave}
          >
            <Text as="h1">Téléchargement</Text>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("vertical")}>
              <Text as="p">Vertical</Text>
              <Img
                src="/images/icons-social-tiy.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("horizontal")}>
              <Text as="p">Horizontal</Text>
              <Img
                src="/images/icon-youtube.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("square")}>
              <Text as="p">Carré</Text>
              <Img
                src="/images/icons-social.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("original")}>
              <Text as="p">Original</Text>
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
