import React from "react";
import ProjectRoutes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from './AuthProvider';
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

const App = () => {
  return (<>
   <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-NGYN9TXYN7"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-NGYN9TXYN7');
        `}
      </script>
    </Helmet>
    <AuthProvider>
      <Router>
        <ProjectRoutes />
        <CookieConsent
          location="bottom"
          buttonText="J'accepte"
          declineButtonText="Je refuse"
          cookieName="myCookieConsent"
          enableDeclineButton={true} // Ajoutez cette ligne pour activer le bouton de refus
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          declineButtonStyle={{ backgroundColor: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Ce site utilise des cookies pour améliorer l'expérience utilisateur.{" "}
          <Link to="/privacy-policy" style={{ color: "#ffd700",marginTop:"10px" }}>
            Politique de confidentialité
          </Link>
        </CookieConsent>
      </Router>
    </AuthProvider>

  </>
    
  );
};

export default App;
